@import "./def";

.transitionable {
  transition: opacity $image-transition-dur $image-transition-ease;

  img {
    opacity: 1;
    transition: opacity 350ms ease;
    transition-delay: 100ms; // Allows for a cycle to render the new logo.
  }

  &.transition {
    img {
      opacity: 0;
    }
  }

  &:not(.loaded) {
    opacity: 0;
  }
}

.container {
  position: relative;
}