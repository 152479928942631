@import "../../../def";

$size-xtiny: 14px;
$size-tiny: 18px;
$size-small: 24px;
$size-large: 48px;

.task-indicator-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    $size: 80px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: var(--color-background-1);
  }
}

.task-indicator {
  display: inline-block;
  width: $size-small;
  height: $size-small;
  position: relative;

  &.animated {
    animation: ti-appear 300ms cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }

  &.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.xtiny {
    width: $size-xtiny;
    height: $size-xtiny;

    [data-spinner] {
      border-width: 2px;
    }
  }

  &.tiny {
    width: $size-tiny;
    height: $size-tiny;

    [data-spinner] {
      border-width: 2px;
    }
  }

  &.large {
    width: $size-large;
    height: $size-large;
  }

  [data-spinner] {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 50%;
    height: 100%;
    border: 3px solid var(--color-primary);
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    animation: spinner-spin 750ms linear;
    animation-iteration-count: infinite;
  }
}

@keyframes spinner-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes ti-appear {
  from {
    width: 0;
  }
}