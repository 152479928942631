$ease: ease-out;
$dur: 150ms;

body.lock-nav {
  .section-nav {
    opacity: 0.75;
    cursor: not-allowed;
    pointer-events: none;
  }
}

body[data-stalled] {
  button, a {
    opacity: 0.75;
    pointer-events: none;
  }
}

.section-nav {
  transition: opacity $ease $dur;
}