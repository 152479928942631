@import "../components/core/ScrollBar/def";

//  Use this class to add a custom scroll bar to your element.
//  The scrollbar styles below should reflect those of the ScrollBar component.
.custom-scrollbar {
  &.scrollbar-hover,
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-handle-color-hover;
    }

    &::-webkit-scrollbar-track {
      background-color: $scrollbar-track-color;
    }
  }

  &:active {
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-handle-color-moving;
    }
  }

  &::-webkit-scrollbar {
    width: $scrollbar-height;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($scrollbar-track-color, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-handle-color;
    border-radius: $scrollbar-height / 2;
  }
  
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb  {
    transition: background-color 250ms ease-out;
  }
}