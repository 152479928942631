.screen {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: space-around;
  justify-content: center;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  padding: 80px 0 36px 0;
  transform: translateY(-50%);
  max-height: 720px;
  text-align: center;
  box-sizing: border-box;

  > div {
    max-width: 560px;
    width: 100%;
    margin: auto;
  }
}

p.phone {
  display: inline-block;
  margin: 6px 0 0 0 !important;
  color: var(--color-text-2);

  .icon {
    transform: rotate(90deg); //  phone-alt seems broken.
  }
}

.sadBoi {
  font-size: 144px;
  color: var(--color-text-4);
}

.support {
  text-align: center;
  margin: 24px 0 36px 0;
}

.technical {
  text-align: center;
  color: var(--color-text-3);

  > h3 {
    white-space: nowrap;
  }

  p {
    max-width: 360px;
    margin: auto;
  }
}

.screen,
.support,
.technical {
  color: var(--color-text-2);

  h1 {
    flex-direction: column;
    margin-bottom: 0;
    span {
      display: block;
    }
  }
}