$breakpoint-xsm: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

$field-width: 210px;

$stall-ease: ease-out;
$stall-dur: 150ms;

$box-shadow-floating: 0 2px 16px rgba(10, 10, 10, 0.175);