.column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-grow {
  flex-grow: 1;
}

.flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}