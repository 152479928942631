@import "../def";

input[type=text],
input[type=date],
input[type=number],
input[type=email],
input[type=password],
input[type=tel],
.input-like,
.date-input,
textarea {
  border: none;
  border-bottom: 2px solid var(--color-background-4); // Slightly more visible than standard border color.

  &[data-input-style="1"] {
    //  Full border.
    border: 1px solid var(--color-background-4);
    background: var(--color-background-1);
    border-radius: 4px;
    transition: border-color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  padding: 8px 12px;
  outline: none !important;
  font-size: 16px;
  color: var(--color-text-2);
  background: transparent;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  min-width: 36px;
  position: relative;
  font-weight: normal;
  font-weight: var(--font-weight-default);

  &::after {
    $h: 2px;
    transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scaleX(0);
    position: absolute;
    bottom: -$h;
    left: 0;
    width: 100%;
    height: $h;
    background: var(--color-primary);
    content: "";
  }

  &.invalid {
    border-bottom-color: var(--color-danger);
  }

  //  nested inputs and input likes.
  input:not([type="checkbox"]),
  .input-like,
  textarea {
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;

    &::before,
    &::after {
      content: unset;
    }
  }

  &.narrow {
    width: 80px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:focus,
  &.focus { 
    &:not([data-input-style]) {
      &::after {
        transform: scaleX(1);
      }
    }

    &[data-input-style="1"] {
      border-color: var(--color-primary);
    }
  }

  &.full-w {
    width: 100%;
  }
}

input,
textarea {
  &::placeholder {
    color: var(--color-text-4);
  }
}

.date-input {
  display: inline-flex;

  &::after {
    bottom: -2px;
  }
}

/* hide HTML5 numeric arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.field {
  display: flex;
  flex-direction: column;

  .label-container {
    width: 100%;
    text-align: left;
  }
}

textarea {
  line-height: 140%;
  vertical-align: top;
}

label,
.label {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-text-3);
}

.select-like-container {
  position: relative;
  display: inline-block;

  .input-like {
    &::after {
      bottom: -2px;
    }
  }
}

.expand-icon {
  display: inline-flex;

  .icon {
    font-size: 12px;
  }

  + .selected {
    text-indent: 8px; 
  }
}

.select-like {
  .selected {
    .icon {
      margin-right: 10px;
    }

    .tooltip-trigger {
      .icon {
        margin: 0;
      }
    }
  }
}

.select-like {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  
  &:not(.input-like):not(.collapse) {
    width: $field-width;
  }

  .item {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* label */
  .selected {
    flex-grow: 1;
  }

  .expand-icon {
    opacity: 0.85;
  }

  &:hover {
    cursor: pointer;

    .expand-icon {
      opacity: 1;
    }

    &:not(.input-like) {
      background-color: var(--color-element-hover);
    }
  }

  &:not(.input-like) {
    border-radius: 4px;
  }

  .placeholder {
    color: var(--color-text-4);
  }
}

.text-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 6px;
  background-color: var(--color-input-background);

  &.minimize-aside-component-spacing {
    input {
      padding-left: 0 !important;
    } 
  }
  
  &.has-cycled-placeholder {
    input,
    textarea {
      &::placeholder {
        transition: opacity 350ms ease-out;
        opacity: 0;
      }
    }

    &.is-cycled-placeholder-active {
      input,
      textarea {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  input,
  textarea {
    &:first-child {
      flex-grow: 1;
      padding: 0;
      border: none;
    }
  }

  .text-input-aside {
    height: 22px;
    color: var(--color-text-3);

    .icon {
      user-select: none;
      color: var(--color-text-4);
    }

    &.flex-center {
      height: auto;
    }
  }
}

.text-input-container,
.date-input {
  &.disabled {
    opacity: 0.5;

    input {
      &::placeholder {
        color: var(--color-text-4);
      }
    }
  }
}