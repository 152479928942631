@import "../../../def";

$cos-45deg: 0.707106781186548;

$shadow-clip-margin: 8px; /* prevents clipping shadow */
$shadow-radius: 2px;
$notch_size: 24px;
$offset: 16px;

.container {
  position: absolute;
  left: 50%;
  z-index: 99;

  &[data-vertical-position="top"] {
    .transformedContainer:not(.open):not([data-animation-type="simple"]) {
      transform: translateY(8px);
    }
  }

  &[data-vertical-position="middle"] {
    &[data-horizontal-position="right"] {
      .transformedContainer:not(.open):not([data-animation-type="simple"]) {
        transform: translateX(-8px);
      }
    }

    &[data-horizontal-position="left"] {
      .transformedContainer:not(.open):not([data-animation-type="simple"]) {
        transform: translateX(8px);
      }
    }
  }

  &[data-vertical-position="bottom"] {
    .transformedContainer:not(.open):not([data-animation-type="simple"]) {
      transform: translateY(-8px);
    }
  }

  &[data-vertical-position="middle"] {
    bottom: 50%;

    .notchContainer {
      top: 50%;
    }

    &[data-horizontal-position="right"] {
      left: unset;
      right: -$offset;

      .notchContainer {
        transform: translate(-34px, -50%) rotate(270deg);
      }
    }

    &[data-horizontal-position="left"] {
      .notchContainer {
        transform: translate(-14px, -50%) rotate(90deg);
      }
    }
  }

  &[data-vertical-position="top"] {
    top: -$offset;

    .notchContainer {
      transform: translate(-50%, #{$notch_size / 2 - $shadow-clip-margin - 4px}) rotate(180deg);
    }
  }

  &[data-vertical-position="bottom"] {
    bottom: -$offset;
  }
}

.body,
.notch {
  box-sizing: border-box; // better for overflow: auto
  box-shadow: 0 0px $shadow-radius rgba(0, 0, 0, 0.45);
}

.body {
  $padding: 18px;
  background: var(--color-background-2);

  &:not(.noMaxWidth) {
    min-width: #{180px + $padding * 2};
  }
  
  &,
  p {
    color: var(--color-text-3);
  }

  position: absolute;
  transform: translate(-50%);
  border-radius: 10px;
  padding: $padding;

  &:not(.noTopSpacing) {
    padding-top: #{$padding + 16px};
  }
}

.notch {
  background: var(--color-background-2);
  width: $notch_size;
  height: $notch_size;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, #{$notch_size * (1 - $cos-45deg) + $shadow-clip-margin}) rotate(45deg);
}

/* clips the notch */
.notchContainer {
  width: #{$notch_size * 2};
  height: #{$notch_size / 2 + $shadow-clip-margin};
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translate(-50%, #{-$notch_size / 2 - $shadow-clip-margin});
  z-index: 99;
  pointer-events: none;
}

.closeButton {
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;
  z-index: 99;
}

.title {
  color: var(--color-text-3);
  font-size: 14px;
  flex-grow: 1;
}

/* Use an empty <div> with this class to anchor the popover at the center */
.anchor {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  &[data-vertical-align="top"] {
    top: 0;
  }

  &[data-vertical-align="middle"] {
    top: 50%;
    transform: translate(-50%, -14px);
  }

  &[data-vertical-align="bottom"] {
    top: 100%;
  }

  &[data-horizontal-align="right"] {
    left: 0;
  }
}

.head {
  $height: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 18px 0 8px 0;
  height: $height;
  position: relative;
  border-radius: #{$height / 2};
  user-select: none;

  &.interactableMasterHead {
    &:hover {
      background-color: var(--color-element-hover);
    }

    cursor: pointer;
  }
}

.masterHead {
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin-top: 0;
  padding-left: 18px;
  padding-right: 8px;
  z-index: 99;
}

.asideList {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px; // The high gap is due to there being a button for clearing the date next to the button for closing the popover, and it's to improve UX on a tablet. Lessen the gap if needed, but take this into account.
}

.transformedContainer {
  position: absolute;

  $duration: 250ms;
  $ease: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  $opacity-ease-out: cubic-bezier(0.165, 0.84, 0.44, 1); // slightly quicker
  $opacity-ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  z-index: 99;

  &:not(.open) {
    opacity: 0;
    pointer-events: none;
    transition-timing-function: $opacity-ease-in, $ease;
  }

  opacity: 1;
  transform: none; 
  transition: opacity $duration $opacity-ease-out, transform $duration $ease;
}
