table {
  width: 100%;
  border-collapse: collapse;

  &:not(.no-border) {
    tr {
      &:not(:last-of-type):not(.no-border) {
        > td {
          border-bottom: 1px solid var(--color-background-3);
        }
      }
    }
  }

  th,
  td {
    text-align: left;
    padding: 0 12px;
    height: 42px;
    box-sizing: border-box;
  }

  tr.expandable-row {
    td {
      height: 0;
      padding: 0;
    }
  }

  th {
    padding-bottom: 8px;

    .help-icon {
      display: inline-block;
      margin-left: 6px;
      color: var(--color-text-3);
    }
  }

  tr {
    &.disabled {
      opacity: 0.75;
      filter: grayscale(100%);
      pointer-events: none;
    }
  }

  &.inline {
    table-layout: fixed;
    width: auto;
  }
}