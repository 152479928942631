@import "../def";

a {
  color: var(--color-primary);
  text-decoration: none;
  cursor: pointer;
}

h1 {
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;

  + .subtitle {
    position: relative;
    top: -48px;
  }

  > .tooltip-trigger {
    display: flex;
  }
}

h2 {
  font-weight: 400;
  margin-bottom: 18px;
  color: var(--color-text-2);
}

h3 {
  color: var(--color-text-2); 
  font-weight: 400;
}

.nowrap {
  white-space: nowrap;
}

.subtitle {
  font-size: 15px;
  color: var(--color-text-3);
  transition: opacity 250ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: 1;
  margin-bottom: 18px;

  &.inset {
    margin-left: 4px;
  }

  &.adjusted {
    transform: translate(2px, 3px);
    margin-bottom: 0;
    height: 0;
    overflow: visible;
  }

  &:empty {
    &::after {
      content: "|";
      visibility: hidden;
    }

    opacity: 0;
  }

  .task-indicator {
    position: absolute;
    transform: translate(12px, 2px);
  }
}

small {
  &.subtitle {
    padding: 6px 0 2px 0;
    font-size: smaller;
  }
}

p,
span {
  &.dimmed {
    color: var(--color-text-3);
  }
}

.color-primary {
  color: var(--color-primary);
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
