.fade-in {
  animation: fade-in 250ms ease-out;
}

@keyframes fade-in {
  from { opacity: 0; } to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; } to { opacity: 0; }
}