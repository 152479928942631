.page-enter,
.page-exit,
.page-enter-active,
.page-exit-active  {
  position: absolute;
  left: 0;
  right: 0;
}

$translate-offset: 24px;
$translate-offset-h1: 24px;
$ease-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-opacity: cubic-bezier(0.23, 1, 0.32, 1); // sligthly quicker
$dur: 250ms;

.page-enter {
  transform: translateX($translate-offset);
  opacity: 0.01;

  h1:not([data-no-sway]) {
    transform: translateX($translate-offset-h1);
  }

  .transition-preserve {
    transform: translateX(-$translate-offset);

    h1:not([data-no-sway]) {
      transform: none;
    }
  }

  &.page-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity $dur $ease-out-opacity, transform $dur $ease-out;

    h1:not([data-no-sway]),
    .transition-preserve {
      transform: none;
      transition: transform $dur $ease-out;
    }
  }
}

.page-exit {
  opacity: 1;
  transform: none;

  h1:not([data-no-sway]) {
    transform: none;
  }
  
  &.page-exit-active {
    opacity: 0.01;
    transform: translateX(-$translate-offset);
    transition: opacity $dur $ease-out-opacity, transform $dur $ease-out;

    h1:not([data-no-sway]) {
      transform: translateX(-$translate-offset-h1);
      transition: transform $dur $ease-out;
    }

    .transition-preserve {
      transition: transform $dur $ease-out;
      transform: translateX($translate-offset);

      h1:not([data-no-sway]) {
        transform: none;
      }
    }
  }
}