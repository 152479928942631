.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .input {
    font-size: 20px;
  }

  > div:first-of-type {
    .input {
      text-align: right;
    }
  }
}