@import "../../../def";

$disabled: var(--color-background-4);

.checkbox {
  $size: 18px;

  &,
  &::before,
  &::after {
    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  font-size: $size;
  position: relative;
  appearance: none;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  color: var(--color-primary);
  padding: 6px;
  box-sizing: content-box;
  border-radius: 50%;
  outline: none !important;
  background-color: transparent;

  transition: background-color 150ms ease-out;

  $ease: cubic-bezier(0.33, 1, 0.68, 1);
  $dur: 250ms;

  &:focus,
  &:not([disabled]):hover {
    background: var(--color-background-3);
  }

  &:not([disabled]):active {
    background: var(--color-background-4);
  }

  &::before {
    min-width: #{$size - 4px};
    max-width: #{$size - 4px};
    min-height: #{$size - 4px};
    max-height: #{$size - 4px};
    position: absolute;
    content: '' !important;
    opacity: 0;
    transition: opacity $ease $dur;
    border: 2px solid;
    border-color: var(--color-primary);
    border-radius: 50%;
    background-color: var(--color-input-background);
  }

  &::after {
    position: absolute;
    font-size: 12px;
    content: "\f00c" !important;
    border-radius: 50%;
    background: var(--color-primary);
    color: var(--color-background-1);
    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;
    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) $dur, opacity $ease $dur;
  }

  &.type-radio {
    &::after {
      content: '\f111' !important;
      font-size: 6px;
    }
  }

  &[disabled] {
    &::before {
      border-color: $disabled;
    }

    &::after {
      background: var(--color-background-1);
      color: $disabled;
    }
  }

  &:not(:checked) {
    &::before {
      opacity: 0.75;
    }
    
    &::after {
      transform: scale(0.2);
      opacity: 0;
    }
  }
}

.checkbox-field {
  &,
  > .label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .label {
    color: var(--color-text-3);
    margin: 0 0 0 3px;
    cursor: pointer;
    user-select: none;
    flex-grow: 1;

    &:hover {
      color: var(--color-text-2);
    }
  }

  &.disabled {
    .label {
      &,
      &:hover {
        color: var(--color-text-4);
      }
    }
  }
}