//  Here is a list of `position: fixed` viewport layers. We put them in this file to more easily maintain z indices.
//  Prioritize each layer with a z-index.

#main {
  display: flex;
  flex-direction: column;
  gap: 24px; // Gap between GlobalViewLinkList and other elements.
  max-width: 1920px;
  box-sizing: border-box;
  padding: 80px;
  padding-bottom: 0; // Bottom padding is handled per component.
  padding-top: 0; // Note that GlobalViewLinkList is a child of this layer.
}

body[data-fullscreen] {
  #main {
    padding-top: 36px;
  }
}

html[data-apptype="offerdocument"] {
  #main {
    padding: 0;
  }
}

.viewport-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  pointer-events: none;
  
  > * {
    pointer-events: auto;
  }
}

#widgets {
  z-index: 800;
  display: flex;  //  Fixes problem with iOS 11 keyboard covering widgets.
  padding-left: 5%;
  padding-right: 15%;
  align-items: flex-end;

  &,
  #left-widgets,
  #right-widgets {
    gap: 24px;
  }

  #left-widgets,
  #right-widgets {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  #left-widgets {
    flex-grow: 1;
  }
}

#floating-elements {
  z-index: 850;
}

#popovers,
#modal-popovers {
  position: absolute; // Fix for usePortal.
  z-index: 900;
}

#notifications {
  z-index: 950;
}

#modals {
  z-index: 1000;
}

#modal-popovers {
  z-index: 1100;
}

#product-finders {
  z-index: 1100;
}

#dropdowns {
  z-index: 1300;
}

#tooltips {
  z-index: 1900;
}

#helper {
  visibility: hidden;
  z-index: -1;
  overflow: hidden;
  width: 0;
  height: 0;
}

#helper p,
textarea {
  word-break: break-word;
}

@media (max-width: $breakpoint-md) {
  #main {
    padding-left: 24px;
    padding-right: 24px;
  }

  html[data-apptype="offer"],
  html[data-apptype="offerwebform"] {
    #main {
      padding-left: 0;
      padding-right: 0;
    }
  }
}