@import "../../../def";

$height: 42px;

.notification {
  width: auto !important;

  z-index: 9999;
  position: fixed;
  background: var(--color-background-2);
  left: 50%;
  bottom: 48px;
  height: $height;
  border-radius: #{$height / 2};
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.175);
  transform: translate(-50%);
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0 18px;
  min-width: 240px;

  $dur: 350ms;
  $ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  $ease-in: cubic-bezier(0.47, 0, 0.745, 0.715);

  transition: opacity $dur $ease-out, transform $dur $ease-out;

  &:not(.visible) {
    pointer-events: none;
    opacity: 0;
    transform: translate(-50%, 24px);
    transition-timing-function: $ease-in, $ease-in;
  }

  .text,
  .icon {
    color: var(--color-button-simple);
    font-size: 16px;
  }

  .text {
    display: inline-block;
    flex-grow: 1;
    padding: 0 24px;
    text-align: center;
  }

  &.visible {
    .icon {
      animation: icon-appear 350ms ease-in-out 450ms both;
    }
  }
}

@keyframes icon-appear {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }

  50% {
    opacity: 1;
    transform: scale(1.15) rotate(-10deg);
  }

  100% {
    transform: scale(1);
  }
}