@import "./text.scss";
@import "./form.scss";
@import "./table.scss";
@import "./page_transitions.scss";
@import "./layout.scss";
@import "./animations.scss";
@import "./transitions.scss";
@import "./nav_lock.scss";
@import "./login/base.scss";
@import "./layers.scss";
@import "./scrollbar.scss";

body[data-environment="dev"] {
  iframe {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  font-family: var(--font-family-default);
}

html {
  max-width: 1920px;
  margin: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; // Removes the gray highlight when tapping on anything.
  font-weight: normal;
  font-weight: var(--font-weight-default);
  color: var(--color-text-2);
  padding: 0;
  background-color: var(--color-background-1);
  
  &[data-apptype="offerwebform"] {
    //  Inherit the top window's background.
    background-color: transparent;
  }
  
  &:not([data-router="login"]):not([data-apptype="offerdocument"]):not([data-apptype="offer"]):not([data-apptype="offerwebform"]) {
    min-width: 800px;
  }

  &[data-apptype="offerdocument"] {
    body {
      overflow-x: hidden;
    }
  }
  
  &[data-apptype="expressflow"] {
    body {
      padding-bottom: 64px;
    }
  }

  &.no-scroll {
    &,
    body {
      overflow: hidden;
    }
  }

  &[data-apptype="expressflow"] {
    touch-action: none; // Disable pinch-to-zoom on express flow.
  }
}

body {
  width: 100%;
  position: relative;
  overflow-y: scroll; // don't flicker scrollbar

  &.dragging {
    * {
      user-select: none;
    }
  }
}

html[data-apptype="offerwebform"] {
  body {
    overflow-y: hidden; //  Reduce flicker while transitioning between steps.
  }
}

ul {
  list-style: none;

  &.default {
    padding-left: 16px;
    list-style: revert;
  }
}

.hidden {
  position: absolute;
  left: -50vh;
  visibility: hidden;
  pointer-events: none;
}

.no-interaction {
  pointer-events: none !important;
}

.nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}

.grow-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.shy {
  opacity: 0.65;

  &:hover {
    transition-duration: 150ms;
    opacity: 1;
  }
}

.ReactCollapse--collapse {
  transition: height 350ms cubic-bezier(0.33, 1, 0.68, 1);
}

@media (max-width: $breakpoint-sm) {
  
  html[data-apptype="offerdocument"] body {
    //  Align the keypad at the bottom.
    padding-bottom: 0;
  }
}